:root {
  --bottom-grad-color: #03e5b7;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  color: white;
  font-family: Orbitron;
}

#root {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: -webkit-linear-gradient(black,  #037ade 40%, var(--bottom-grad-color));
    background: linear-gradient( black, #037ade 40%, var(--bottom-grad-color));
    transition: background 0.8823s ease;
}

#audio-controller {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

#audio-controller.loaded {
  bottom: auto;
  left: auto;
  width: 0px!important;
  height: 0px!important;
}

#play-icon-holder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  opacity: 1;
  background: black;
  transition: all 1s ease;
}

#play-icon-holder.fade-out {
  opacity: 0;
}

#play-icon-holder.loaded {
  bottom: auto;
  left: auto;
  opacity: 0;
}

#play-icon {
  display: none;
  position: fixed;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  z-index: 1;
  width: 120px;
  height: 120px;
  fill: white;
  opacity: 1;
  transition: all 1s ease;
}

#play-icon.show {
  display: block;
}

#play-icon.fade-out {
  opacity: 0;
}

.loader {
  text-align: center;
}